import React from 'react';
import styled from 'styled-components';
import { StatsTilesContainer } from './Card';

const StyledPageContent = styled(StatsTilesContainer)`
  padding: 1rem 1rem;
  max-height: calc(100vh - var(--headerHeight) - var(--marginTopContent));
  overflow-y: auto;
`;

interface PageContentProps {
  children: React.ReactNode;
}

export const PageContent = ({ children }: PageContentProps) => {
  return <StyledPageContent>{children}</StyledPageContent>;
};
