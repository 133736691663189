import Head from 'next/head';
import Link from 'next/link';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { RouterConfig } from '../config/router';
import { PageWithTitle } from '../components/PageWithoutTitle';
import Typography from '@material-ui/core/Typography';
import { useSession, signIn } from 'next-auth/react';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components';
import { WaitingList } from '../components/WaitingList';
import { StatsContentContainer, StatsTilesContainer } from '../components/Card';
import { PageContent } from '../components/PageContent';

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
`;
const StyledText = styled.p`
  color: #777;
`;
export default function Home() {
  const { data: session, status } = useSession();
  return (
    <>
      <Head>
        <title>Staty | Home</title>
        <meta name="keywords" content="statystyki" />
      </Head>
      <PageWithTitle title="" />
      <PageContent>
        <WaitingList />

        <StyledText>
          Staty.pro to serwis w którym znajdziesz statystyki sportowe pokazane w prosty i przejrzysty sposób. Dzięki temu zaoszczędzisz czas na
          przeszukiwaniu wileu stron, dokonasz trafniejszych wyborów przy obstawianiu kuponów i dzięki temu będziesz miał więcej zielonych kuponów.
          <br />
          Pamiętaj to jest analiza i statystyka. Jest ona bardzo pomocna przy typowaniu meczy, jednak kazdy typ powinien być przez Ciebie odpowiednio
          przemyślany.
        </StyledText>
        {/* <StyledText>Tutaj będzie zawarty odnośnik do filmiku, który będzie pokazywał jak korzystać z serwisu</StyledText> */}

        {!session ? (
          <FlexContainer>
            <Typography variant="h5" component="h3">
              Aby korzystać z aplikacji musisz się zalogować
            </Typography>
            <Center>
              {status !== 'loading' ? (
                <Box>
                  <Button variant="outlined" color="primary" onClick={() => signIn()}>
                    Zaloguj się
                  </Button>
                </Box>
              ) : (
                <CircularProgress />
              )}
            </Center>
          </FlexContainer>
        ) : null}

        <Center>
          <Link href={RouterConfig.AllMatches} passHref>
            <Button variant="contained" color="primary">
              Zobacz Wszystkie Mecze
            </Button>
          </Link>
        </Center>
      </PageContent>
    </>
  );
}
