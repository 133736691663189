import React from "react";
import styled from "styled-components";

export const CardContainer = styled.div<{ withMargin?: boolean }>`
  margin-top: 10px;
  margin-right: ${({ withMargin }) => (withMargin ? "10px" : 0)};
  
  border: 1px solid ${({ theme }) => theme.borderColor};
  color: black;
  border-radius: 15px;
  overflow: hidden;
  /* margin-top: 0; */
  &:first-of-type {
    margin-top: 0;
  }
  /* &:first-child {
    margin-top: 0;
  } */
`;

export const NewCardContainer = styled.div`
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  border-radius: 25px;
  background: white;
  border: none;
  margin-top: 10px;
  overflow: hidden;
  &:first-of-type {
    margin-top: 0;
  }
`

export const StatsTilesContainer = styled(CardContainer)`
  border-radius: 25px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px, rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  border-radius: 25px;
  background: white;
  border: none;
  
  &&& {
    margin-top: var(--marginTopContent);
  }
`

export const StatsContentContainer = styled.div`
  margin: 1rem 0.5rem;
  padding: 0 1rem;
`;

export const CardTitle = styled.p<{ isBigFont?: boolean }>`
  color: ${({ theme }) => theme.cardFontColor};
  background: ${({ theme }) => theme.colorMain};

  padding: 0.5rem;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-size: ${({ isBigFont }) => (isBigFont ? "24px" : "16px")};
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

export const ContentContainer = styled.div`
  margin: 1rem 0.5rem;
`;

type CardProps = {
  children: React.ReactNode;
  withMargin?: boolean;
};
export const Card = ({ children, withMargin }: CardProps) => {
  return <CardContainer withMargin={withMargin}>{children}</CardContainer>;
};
