import React, { useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
// import Box from '@material-ui/core/Box';
// import { withStyles } from '@material-ui/core/styles';
// import InputBase from '@material-ui/core/InputBase';
// import InputLabel from '@material-ui/core/InputLabel';
// import Input from '@material-ui/core/Input';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useMutation } from 'react-query';
import axios from 'axios';
import { AxiosError } from 'axios';
import { useToast } from '../hooks/useToast';

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 2rem;
`;

const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  min-width: 300px;
`;

// const StyledPaper = styled(Paper)`
//   &&.MuiPaper-root {
//     position: relative;
//     z-index: 9;
//     border-radius: 30px;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     padding: 2px 4px;
//   }
// `;

const HiddenInput = styled(TextField)`
  &&& {
    &.MuiFormControl-root {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }
`;

const validationSchema = z.object({
  email: z.string().email('Niepoprawny adres email'),
  name: z.string(),
  surname: z.string(),
});

const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

const FormError = styled.div`
  position: absolute;
  color: #d0021b;
  width: 400px;
`;

const StyledButton = styled(Button)`
  &&& {
    &.MuiButton-root,
    &.MuiButtonBase-root {
      font-size: 1.2rem;
      margin-top: 1rem;
    }
  }
`;

export const WaitingList = () => {
  // const classes = useStyles();
  const { addAlert } = useToast();

  const formMutation = useMutation((email: string) => axios.post('/api/waiting-list', { email }), {
    onSuccess: (_, variables) => {
      addAlert({
        key: `onError-${new Date().getMilliseconds()}-api/waiting-list`,
        message: `Adres ${variables} został dodany.\nDziękujemy za zapisanie się na listę oczekujących`,
        type: 'success',
      });
    },
  });
  const { control, handleSubmit, setFocus, register } = useForm({
    defaultValues: {
      name: '',
      surname: '',
      email: '',
    },
    resolver: zodResolver(validationSchema),
  });

  useEffect(() => {
    try {
      setFocus('email');
    } catch (error) {}
  }, [setFocus]);

  const onSubmit = (data) => {
    formMutation.reset();
    const { email, name, surname } = data;
    if (name || surname) {
      console.error('Bot');
      return;
    }
    formMutation.mutate(email);
  };

  return (
    <FlexContainer>
      <Typography variant="h4" component="h1" gutterBottom>
        Dołącz do listy oczekujących użytkowników
      </Typography>
      <Typography>Zapisz się na listę oczekujących, aby otrzymać informację o starcie serwisu</Typography>
      {/* <StyledPaper>
        <InputBase className={classes.input} placeholder={'Imię'} />
      </StyledPaper>
      */}
      {/* <StyledPaper>
              <InputLabel htmlFor="my-input">Imie</InputLabel>
              <InputBase id="my-input" aria-describedby="my-helper-text" className={classes.input} {...field} placeholder={'Imię'} />
              <FormHelperText id="my-helper-text">We'll never share your email.</FormHelperText>
            </StyledPaper> */}
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Controller
          name="name"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <HiddenInput label="Imie" variant="outlined" value={value} onChange={onChange} error={!!error} helperText={error ? error.message : null} />
          )}
        />
        <Controller
          name="surname"
          control={control}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <HiddenInput
              label="Nazwisko"
              variant="outlined"
              value={value}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        <FieldContainer>
          <Controller
            name="email"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <TextField
                {...register('email')}
                label="Email"
                variant="outlined"
                value={value}
                onChange={onChange}
                error={formMutation.isError || !!error}
                helperText={error ? error.message : null}
                type="email"
                fullWidth
              />
            )}
          />
          {formMutation.isError ? <FormError>{(formMutation.error as AxiosError).response.data.message}</FormError> : null}
        </FieldContainer>

        <StyledButton disabled={formMutation.isLoading} variant="contained" color="primary" size="large" type="submit" fullWidth>
          Zapisz się
        </StyledButton>
      </Form>
    </FlexContainer>
  );
};
