import React, { useEffect } from 'react';
import { useAppContext } from '../hooks/useAppContext';

export const PageWithTitle = ({ title }: { title: string }) => {
  const { setPageTitle } = useAppContext();
  useEffect(() => {
    setPageTitle(title);
  }, []);
  return <></>;
};
